import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Col, Container, Row } from 'react-bootstrap'
import {
    SALARY_FREEZE_BUTTON_TEXT,
    SURVEY_UUID_LIST,
    TEXT_REGION_REPORT_SELECTION,
    REGION_SELECTED_TEXT,
    FIRST_HISTORICAL_YEAR,
    TEXT_NO_AVAIL_DATA,
    TABLE_COLUMN_NAME_GROUP,
    TEXT_YEAR_REPORT_SELECTION_HISTORICAL,
    TEXT_SELECT_PLACEHOLDER,
    SURVEY_YEAR_FOR_LAST_AVAILABLE_HISTORICAL_YEAR,
    EXPORT_OPTION_AVALIBLE_SURVEY_LIST,
    SURVEY_COPY_RIGHT_HISTORY,
} from '../constants/constants.js'

import { REGION_OPTIONS } from '../constants/regionConstants'
import { HISTORICAL_REPORT_BASE_SALARY_INCREASE } from '../constants/countryReportConstants'
import {
    cellStyle,
    ReactSelect,
    useQueryState,
    tableFooter,
} from '../helpers/helpers'
import {
    REACT_APP_SERVER_ADDRESS,
    REACT_APP_API_KEY,
    REACT_APP_EXCEL_EXPORT,
} from '../config'
import PageNotFound from './404Page'
import { useParams } from 'react-router-dom'

function Historical() {
    return (
        <div>
            <UserInput />
        </div>
    )
}

const UserInput = () => {
    const params = useParams()
    const survey = SURVEY_UUID_LIST[params.survey]
    const [regionValue, setRegionValue] = useQueryState('region')
    const [showSpinner, setShowSpinner] = useState(true)
    const [items, setItems] = useState([])
    const [responses, setResponses] = useState({})
    const [columnsOnTable, setColumnsOnTable] = useState([])
    // Initialize isDownloading state variable
    const [isDownloading, setIsDownloading] = useState(false)

    // default years is the last available year and the previous 4 years
    const defaultYears = []
    for (
        let i = SURVEY_YEAR_FOR_LAST_AVAILABLE_HISTORICAL_YEAR[survey];
        i >= SURVEY_YEAR_FOR_LAST_AVAILABLE_HISTORICAL_YEAR[survey] - 4;
        i--
    ) {
        defaultYears.push({ label: +i, value: +i })
    }

    // years is the last available year and all the previous years
    const years = []
    for (
        let i = SURVEY_YEAR_FOR_LAST_AVAILABLE_HISTORICAL_YEAR[survey];
        i >= FIRST_HISTORICAL_YEAR;
        i--
    ) {
        years.push({ label: +i, value: +i })
    }

    const [multiYearValue, setMultiYearValue] = useState(defaultYears)

    // columns_to_show added the associated style and percent sign for each column
    let columns_to_show = []
    if (multiYearValue.length > 0) {
        columns_to_show.push({
            label: TABLE_COLUMN_NAME_GROUP,
            value: TABLE_COLUMN_NAME_GROUP,
            style: 'td-name',
            percent: '',
        })
        multiYearValue.forEach((year) => {
            columns_to_show.push({
                label: year.value,
                value: year.value,
                style: 'td-normal',
                percent: '%',
            })
        })
    }
    columns_to_show = columns_to_show.sort((a, b) => a.value - b.value)

    useEffect(() => {
        setShowSpinner(true)
        fetchDataAndUpdateTable()
    }, [regionValue, multiYearValue.length])

    const fetchDataAndUpdateTable = async () => {
        if (!regionValue || multiYearValue.length === 0) {
            return
        }
        const options = {
            method: 'GET',
            url: `${REACT_APP_SERVER_ADDRESS}hist`,
            params: {
                region: regionValue,
                survey: survey,
                years: multiYearValue.map((item) => item.value).join(','),
            },
        }

        try {
            const response = await axios.request(options)
            const respBody = response.data
            setResponses(respBody)
            const newData = respBody.results
            setItems(newData)
            setShowSpinner(false)

            const columnsToShow = columns_to_show.filter(function (obj) {
                return Object.keys(newData[0]).includes(obj.value.toString())
            })

            setColumnsOnTable(columnsToShow)
        } catch (e) {}
    }

    const handleRegionChange = (event) => {
        setRegionValue(event.value)
    }

    const handleMultiYearChange = (event) => {
        setMultiYearValue(event)
    }

    const DisplayDataItems = items.map((row) => {
        return (
            <tr>
                {columnsOnTable.map((column) => {
                    return (
                        <th className={cellStyle(regionValue, row, column)}>
                            {row[column.value] === '*' ||
                            column.value === TABLE_COLUMN_NAME_GROUP
                                ? row[column.value]
                                : row[column.value]
                                      .toFixed(2)
                                      .concat(column.percent)}
                        </th>
                    )
                })}
            </tr>
        )
    })

    const DisplayData =
        showSpinner === true ? (
            <div id="loader"></div>
        ) : items.length > 0 ? (
            <table className="table table-sm">
                <thead className="thead-style">
                    {columnsOnTable.map((column) => (
                        <th className="table-first-row">
                            <div className="vertical-align">
                                {column.label === TABLE_COLUMN_NAME_GROUP
                                    ? ''
                                    : column.label}
                            </div>
                        </th>
                    ))}
                </thead>
                <tbody>{DisplayDataItems}</tbody>
                {tableFooter(survey, 'no', 'yes', false, '')}
            </table>
        ) : (
            <div className="data-availability">{TEXT_NO_AVAIL_DATA}</div>
        )

    function IsInvalidateParamValue() {
        if (
            regionValue &&
            REGION_OPTIONS.find((c) => c.value === regionValue) === undefined
        ) {
            return true
        }
    }
    const handleExcelDownload = async () => {
        setIsDownloading(true) // Set isDownloading to true at the start
        // const apiUrl = 'https://dev.private.culpepper.com/API/exportsbs/export'
        const requestData = responses
        requestData['copyright'] = SURVEY_COPY_RIGHT_HISTORY[survey]
        axios
            .post(REACT_APP_EXCEL_EXPORT, requestData, {
                responseType: 'blob', // Set the response type to 'blob' to receive binary data
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${REACT_APP_API_KEY}`,
                    'Content-Type': 'application/json',
                },
                crossdomain: true,
            })

            .then((response) => {
                // Check the status code of the response
                if (response.status !== 200) {
                    // If the status code is not 200, show an alert to the user
                    alert(
                        "The current download is not working, please use the 'Contact Us' on the bottom of the page to report this issue."
                    )
                } else {
                    // Get the "returnData" field from the API response
                    const blob = new Blob([response.data])
                    const fileUrl = URL.createObjectURL(blob)

                    // Create an anchor element
                    const downloadLink = document.createElement('a')
                    downloadLink.href = fileUrl
                    downloadLink.target = '_blank'
                    downloadLink.download = response.headers[
                        'content-disposition'
                    ]
                        .split('=')[1]
                        .split(';')[0]
                    downloadLink.click()
                }
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setIsDownloading(false) // Set isDownloading to false at the end
            })
    }
    if (IsInvalidateParamValue()) {
        return (
            <div>
                <PageNotFound />
            </div>
        )
    } else {
        return (
            <Container fluid>
                <div>
                    <Row>
                        <Col
                            sm={12}
                            lg={3}
                            className="pane printHidden"
                            style={
                                !regionValue || multiYearValue.length === 0
                                    ? { height: '100vh' }
                                    : {}
                            }
                        >
                            <ReactSelect
                                label={TEXT_REGION_REPORT_SELECTION}
                                className="react-select form-control p-0"
                                placeholder={TEXT_SELECT_PLACEHOLDER}
                                options={REGION_OPTIONS}
                                onChange={handleRegionChange}
                                value={
                                    regionValue
                                        ? REGION_OPTIONS.find(
                                              (c) => c.value === regionValue
                                          )
                                        : 'Global'
                                }
                                closeMenuOnSelect={true}
                            />
                            <ReactSelect
                                label={TEXT_YEAR_REPORT_SELECTION_HISTORICAL}
                                placeholder={TEXT_SELECT_PLACEHOLDER}
                                className="react-select"
                                options={years}
                                onChange={handleMultiYearChange}
                                value={multiYearValue}
                                isDisabled={!regionValue}
                                isMulti={true}
                                closeMenuOnSelect={false}
                            />
                        </Col>
                        <Col
                            sm={12}
                            lg={9}
                            className="table-area"
                            style={
                                multiYearValue.length > 0 && regionValue
                                    ? { display: 'block' }
                                    : { display: 'none' }
                            }
                        >
                            <div className="printVisible">
                                <div className="logo-print-view">
                                    <img
                                        src="https://ww2.culpepper.com/images/logos/Culpepper-Logo-2024.svg"
                                        alt=""
                                        width="238.19"
                                    ></img>
                                </div>
                            </div>
                            <p className="table-title">
                                {HISTORICAL_REPORT_BASE_SALARY_INCREASE}
                            </p>
                            <hr style={{ width: '100%' }} />{' '}
                            {EXPORT_OPTION_AVALIBLE_SURVEY_LIST.includes(
                                survey
                            ) ? (
                                <div>
                                    <img
                                        disabled={isDownloading}
                                        className="export-file-images"
                                        alt="Excel"
                                        src="https://ww3.culpepper.com/l/137751/2016-02-02/23qp6/137751/2041/excel.gif"
                                        onClick={(e) => {
                                            if (isDownloading) {
                                                e.preventDefault()
                                                return
                                            }
                                            handleExcelDownload()
                                        }}
                                        style={{
                                            cursor: 'pointer',
                                            filter: isDownloading
                                                ? 'grayscale(100%)'
                                                : 'none',
                                        }} // Show pointer cursor to indicate clickability and apply grayscale filter if image is disabled
                                    ></img>
                                </div>
                            ) : null}
                            <div className="container container-margin">
                                <div className="row justify-content-start no-gutters">
                                    <div className="col-4 col-md-3 col-xl-2 text-left">
                                        <span className="datacut-type">
                                            {REGION_SELECTED_TEXT}:
                                        </span>
                                    </div>
                                    <div className="col-auto text-left">
                                        {regionValue}
                                    </div>
                                </div>
                                <div className="row justify-content-start no-gutters">
                                    <div className="col-4 col-md-3 col-xl-2 text-left">
                                        <span className="datacut-type">
                                            {SALARY_FREEZE_BUTTON_TEXT}:
                                        </span>
                                    </div>
                                    <div className="col-auto text-left">
                                        <span>Yes</span>
                                    </div>
                                </div>
                            </div>
                            <div className="tableFixedHead-for-print printVisible">
                                {DisplayData}
                            </div>
                            <div className="tableFixHead printHidden">
                                {DisplayData}
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        )
    }
}

export default Historical
