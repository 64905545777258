import { NavLink } from 'react-router-dom'
import { useRef } from 'react'
import {
    COUNTRY_DEFAULT_SELECTION,
    COUNTRY_SHEET_NAME,
    HISTORICAL_DEFAULT_SELECTION,
    HISTORICAL_SHEET_NAME,
    PRACTICE_DEFAULT_SELECTION,
    PRACTICE_SHEET_NAME,
    PRELIM_SURVEY_LIST,
    REGION_DEFAULT_SELECTION,
    REGION_SHEET_NAME,
    SURVEY_UUID_LIST,
} from '../constants/constants'

function Nav() {
    const navLinkStyles = ({ isActive }) => {
        return {
            fontfamily: 'serif',
            fontSize: '15px',
            fontWeight: isActive ? 'bold' : 'normal',
            textDecoration: isActive ? 'underline' : 'none',
            textDecorationColor: isActive ? '#003FA3' : 'none',
            textDecorationThickness: isActive ? '3px' : 'none',
            color: isActive ? '#003FA3' : '#003FA3',
            marginLeft: 0,
        }
    }
    const url = window.location.pathname.split('/')[1]
    const survey = SURVEY_UUID_LIST[url]

    const navButton = useRef(null)
    const linksContainerRef = useRef(null)
    function collapseNav() {
        navButton.current.classList.add('collapsed')
        linksContainerRef.current.classList.remove('show')
    }
    return (
        <nav className="navbar navbar-expand-lg">
            <a className="navbar-brand" href={'http://www.Culpepper.com'}>
                <img
                    src="https://ww2.culpepper.com/images/logos/Culpepper-Logo-2024.svg"
                    alt=""
                    width="238.19"
                ></img>
            </a>

            <button
                ref={navButton}
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navMainMenu"
                aria-controls="navMainMenu"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                {'Menu'}
                <span className={'navbar-toggler-icon'}></span>
            </button>

            <div
                id="navMainMenu"
                className="collapse navbar-collapse"
                ref={linksContainerRef}
            >
                {PRELIM_SURVEY_LIST.includes(survey) ? (
                    <div className="navbar-nav">
                        <NavLink
                            to={url + '/'}
                            className="nav-item nav-link active"
                            style={navLinkStyles}
                            onClick={collapseNav}
                        >
                            Home
                        </NavLink>
                        <NavLink
                            // to={url + '/region'}
                            to={url + REGION_DEFAULT_SELECTION}
                            className="nav-item nav-link"
                            style={navLinkStyles}
                            onClick={collapseNav}
                        >
                            {REGION_SHEET_NAME}
                        </NavLink>
                    </div>
                ) : (
                    <div className="navbar-nav">
                        <NavLink
                            to={url + '/'}
                            className="nav-item nav-link active"
                            style={navLinkStyles}
                            onClick={collapseNav}
                        >
                            Home
                        </NavLink>
                        <NavLink
                            to={url + REGION_DEFAULT_SELECTION}
                            className="nav-item nav-link"
                            style={navLinkStyles}
                            onClick={collapseNav}
                        >
                            {REGION_SHEET_NAME}
                        </NavLink>
                        <NavLink
                            to={url + COUNTRY_DEFAULT_SELECTION}
                            className="nav-item nav-link"
                            style={navLinkStyles}
                            onClick={collapseNav}
                        >
                            {COUNTRY_SHEET_NAME}
                        </NavLink>
                        <NavLink
                            to={url + PRACTICE_DEFAULT_SELECTION[survey]}
                            className="nav-item nav-link"
                            style={navLinkStyles}
                            onClick={collapseNav}
                        >
                            {PRACTICE_SHEET_NAME}
                        </NavLink>

                        <NavLink
                            to={url + HISTORICAL_DEFAULT_SELECTION}
                            className="nav-item nav-link"
                            style={navLinkStyles}
                            onClick={collapseNav}
                        >
                            {HISTORICAL_SHEET_NAME}
                        </NavLink>
                    </div>
                )}
            </div>
        </nav>
    )
}

export default Nav
