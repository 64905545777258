import React from 'react'

const groupStyles = {
    backgroundColor: 'silver',
    fontSize: 15,
    color: 'black',
    textAlign: 'center',
    justifyContent: 'space-between',
    padding: '0.4em',
    borderRadius: '0.5em',
    // border: '1px dotted black',
}

export const formatGroupLabel = (data: GroupedOption) => (
    <div style={groupStyles}>
        <span>{data.label}</span>
    </div>
)

export const customFontStyles = {
    singleValue: (provided) => ({
        ...provided,
        color: '#003FA3',
        fontSize: 14,
    }),
    option: (provided, state) => ({
        ...provided,
        fontSize: 13,
        padding: 3,
        // marginLeft: 5,
    }),
}
